
import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { Link } from 'gatsby';
import { useTranslation } from 'react-i18next';

function Copyright() {
  return (
    <Typography variant="body2" color="white">
      {'Copyright © '}
      <Link className="custom-link" to="https://dn-solutions.at/">
        DN-Solutions
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

export default function StickyFooter() {
  const { t, i18n } = useTranslation();
  return (

    <Box
      component="footer"
      sx={{
        py: 3,
        px: 2,
        mt: 'auto',
        backgroundColor: (theme) =>
          theme.palette.mode === 'light'
            ? theme.palette.grey[800]
            : theme.palette.grey[800],
      }}
    >
      <Container maxWidth="sm">
        <Copyright />
        <Typography variant="body1" color={"white"}>
          <Link className="custom-link" to={`/legalDisclosure`}>{t("Imprint")}</Link> | <Link className="custom-link" to={`/termsOfUse`}>{t("Usage Service")}</Link> | <Link className="custom-link" to={`/privacyPolicy`}>{t("Data Protection")}</Link>
        </Typography>
      </Container>
    </Box>
  );
}