import { Box, Container } from "@mui/material"
import * as React from "react"
import ResponsiveAppBar from "../components/AppBar"
import StickyFooter from "../components/Footer"
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import i18n from "i18next";
import { useTranslation, initReactI18next } from "react-i18next";
import { germanTranslation } from "./language/de";
import { englishTranslation } from "./language/en";
import LanguageDetector from 'i18next-browser-languagedetector';
i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(LanguageDetector)
  .init({
    // the translations
    // (tip move them in a JSON file and import them,
    // or even better, manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)
    resources: {
      ...englishTranslation,
      ...germanTranslation
    },
    fallbackLng: "en",
    interpolation: {
      escapeValue: false // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    }
  });
// markup
const Layout = ({ children }: any) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        maxWidth: '100wh',
      }}
    >
      <ResponsiveAppBar></ResponsiveAppBar>
      <Container style={{backgroundColor: 'white', flexGrow: 1}} maxWidth="xl">
      {children}
      </Container>
      <StickyFooter></StickyFooter>
    </Box>
  )
}

export default Layout
