export const germanTranslation = {
    "de": {
        translation: {
            "german": "Deutsch",
            "english": "Englisch",
            "Responsive Web-Development": "Responsive Web-Entwicklung",
            "High-performance and modern front-end technologies": "Performante und moderne Frontend-Technologien",
            "ReactJS with Gatsby or NextJS and Create-React-App, alternatively Angular 2+ possible": "ReactJS mit Gatsby oder NextJS und Create-React-App, Alternativ auch Angular 2+ möglich",
            "Typescript support, with classes, types and API models. To keep the code clear and concise.": "Typescript Unterstützung, mit Klassen, Typen und API Modellen, damit der Code übersichtlich und klar bleibt.",
            "NPM Dependency Management: Guarantees that the dependencies match. NPM Contributor, automatic tests at CI/CD": "NPM Dependency Management: Es wird garantiert, dass die Dependencies zusammenpassen. NPM Contributor, automatische tests bei CI/CD",
            "Creation of the components with a clear code structure (tree structure). Import, export mechanism of JSON's and other file types. This makes the code clear and easy to understand": "Erstellung der Components mit einer klaren Codestruktur (Baumstruktur). Import, Exportmechanismus der JSONs und anderen Filetypen. Dadurch ist der Code übersichtlich und  verständlich",
            "Cloud, Architecture and Microsoft technologies": "Cloud, Architektur und Microsoft Technologien",
            "Creation of the appropriate resources, which are inserted into groups": "Creation of the appropriate resources, which are inserted into groups",
            "Asp .NET (Core, 5+), C# API (Azure Functions with Timer, Http, BusService Trigger)": "Asp .NET (Core, 5+), C# API (Azure Functions mit Timer, Http, BusService Trigger)",
            "Databases": "Datenbanken",
            "Configuration": "Konfiguration",
            "cost efficient": "Kosteneffizient",
            "Development of mobile applications": "Entwicklung von Mobile Applikationen",
            "Technology React Native: Parallel development in iOS and Android": "Technologie React Native: Parallel Entwicklung in iOS und Android",
            "Push notification, remote updates (EXPO, APK auto updates and downloads without google store), storage management, API support, performance considerations": "Push Notification, Remoteupdates (EXPO, APK auto updates und downloads ohne google store), Storage Management, API Unterstützung, ausführliche Performanceoptimierungen",
            "Material Design, Custom Designs, Animations, Flexible UI Customization, UI Libraries": "Material Design, Eigene Design, Animationen, Flexible UI Anpassung, UI Libraries",
            "Develop native components: Bluetooth, NFC, WIFI, Command Shell and other functions": "Native Komponenten entwickeln: Bluetooth, NFC, WIFI, Command Shell und weitere Funktionen",
            "Fullstack developer with an overview of the entire application": "Fullstack-Entwickler mit Übersicht über die gesamte Applikation",
            "Development can take place in many areas.": "Entwicklung kann in vielen Bereichen stattfinden.",
            "The focus is on architecture (Azure, DevOps), .Net C#, React.": "Der Fokus liegt in Architektur (Azure, DevOps), .Net C#, React.",
            "Support is also available in Angular, PHP Laravel and other technologies upon request.": "Bei Anfrage gibt es auch in Angular, PHP Laravel und anderen Technologien Support.",
            "Results and customer satisfaction are primary for this company": "Ergebnisse und Kundenzufriedenheit sind für diese Firma primär",
            "Complete development of applications": "Komplette Entwicklung von Applikationen",
            "The company gladly accepts new as well as existing greenfield projects.": "Das Unternehmen nimmt gerne neue, aber auch bestehende Greenfield Projekte an.",
            "We would be happy to advise you on where the problem lies in the application.": " Wir beraten Sie auch bei Problemen in Ihrer Applikation.",
            "References": "Referenzen",
            "DN-Solutions has worked successfully with big-name companies.": "DN-Solutions hat schon mit Unternehmen mit großem Namen erfolgreich zusammengearbeitet.",
            "It is always a pleasure to bring even more success to a customer.": "Es ist immer eine Freude, einem Kunden noch mehr Erfolg zu bringen.",
            "Your success is our success.": "Ihr Erfolg ist unser Erfolg.",
            "We have already worked successfully with the following companies:": "Mit folgende Unternehmen wurde schon erfolgreich zusammengearbeitet:",
            "Owner and Developer: Ing. Nicholas Grill, BSc.": "Besitzer und Entwickler: Ing. Nicholas Grill, BSc.",
            "Nicholas Grill has led several projects to success. He is always looking forward to new challenges.": "Nicholas Grill hat schon mehrere Projekte zum Erfolg geleitet. Er freut sich immer auf neue Herausforderungen.",
            "Due to his great motivation he can achieve great results for your projects.": "Durch seine große Motivation kann er großartige Ergebnisse für Ihre Projekte erzielen.",
            "Resume": "Lebenslauf",
            "It is also possible to develop for both platforms in Native (Bluetooth, NFC, Shell commands supported with it).": "Auch besteht damit die Möglichkeit für beide Plattformen in Native zu entwickeln (Bluetooth, NFC, Shell commands damit unterstützt).",
            "Mobile applications can be developed in React Native for iOS and Android": "Mobile Anwendungen können in React Native für iOS und Android entwickelt werden",
            "Architectures, CI/CD, Git, DevOps and Cloud infrastructure: Consulting and construction (Azure)": "Architekturen, CI/CD, Git, DevOps und Cloud Infrastruktur: Beratung und Aufbau (Azure)",
            "Azure resources: Resource groups constructed with e.g. Storage, App Config, App Service (Kudo), App Insights, Logs": "Azure Ressourcen: Resource Groups zusammengestellt mit z.B. Storage, App Config, App Service (Kudo), App Insights, Logs",
            "Support and contact": "Support und Kontakt",
            "projects and references": "Projekte und Referenzen",
            "We are happy to help you with your affairs.": "Wir helfen Ihnen gerne bei Ihren Angelegenheiten.",
            "Through the experience and passion for software development,": "Durch Erfahrungen und Leidenschaft für die Softwareentwicklung,",
            "we are the ideal partner to solve your software problems.": "sind wir der ideale Partner, um Ihre Software Probleme zu lösen.",
            "Data evaluation (table, charts), design, performance or optimization ideas.": "Datenauswertung (Tabellen, Diagramme), Design, Performance oder Optimierungsideen.",
            "We have the right answer for you.": "Wir haben die richtige Antwort für Sie.",
            "Where to find us:": "Wo Sie uns finden können:",
            "Company location: Lower Austria / Vienna": "Firmenort: Niederösterreich / Wien",
            "Telephone number:": "Telefonnummer:",
            "Direct contact to the chief developer": "Direkt Kontakt zum Chef-Entwickler",
            "Welcome to DN-Solutions contact and support": "Willkommen beim DN-Solutions Kontakt und Support",
            "We offer you the following services:": "Wir bieten Ihnen folgende Services an.",
            "Development: A completely new application will be developed for you from start to finish.": "Entwicklung: Gerne wird für Sie eine komplett neue Applikation von Anfang bis zu Ende entwickelt.",
            "Architecture / Dev Ops / Agile methods: everything is built for you. Advice is given on which technologies are to be used for the project. From cloud system and hosting, version control system (Git), to frontend or backend creation.": "Architektur / Dev Ops / Agile Methoden: Alles wird für Sie erstellt. Beraten wird über, welche Technologien für das Projekt eingesetzt wird. Von Cloud System und Hosting, Version Control System (Git), bis zu Frontend -bzw. Backenderstellung.",
            "Support & Code Reviews: Problems with your current application? The problem will be analyzed and solved for you.": "Support & Code Reviews: Probleme bei Ihrer aktuellen Applikation? Das Problem wird analysiert und für Sie gelöst.",
            "Maintenance: Existing applications are also gladly maintained and kept alive. It is suggested if you should perform a new update for certain libraries/plugins so that the application continues to work properly.": "Wartung: Auch bestehende Applikationen werden gewartet und am Leben gehalten. Es wird analysiert, ob man bei Libraries/Plugins ein neues Update durchführen sollte, damit die Applikation weiterhin zuverlässig funktioniert.",
            "We can offer you the following models for cooperation:": "Wir können Ihnen für die Zusammenarbeit folgende Modelle anbieten.",
            "A work contract: Payment is based on results.": "Einen Werkvertrag: Es wird nach Ergebnissen bezahlt.",
            "Hourly rate: An amount is agreed on how much € per hour is billed for the work.": "Stundensatz: Es wird ein ein Betrag vereinbart, wieviel pro Stunde für die Arbeit verrechnet wird.",
            "Hourly rate with bonus model: A fixed hourly rate is selected, with a bonus model if the results match.": "Stundensatz mit Prämienmodell: Es wird ein fixer Stundensatz ausgewählt mit einem Prämienmodell, wenn die Ergebnisse passen.",
            "What services do we offer you?": "Welche Services bieten wir Ihnen?",
            "We do the best Services for you": "Wir bringen die besten Ergebnisse",
            //references and projects
            "countbirdText": "Eine erfolgreiche international verkaufte app, die auch im Fernsehen z.B. im ORF ausgestrahlt wurde (Guten Morgen Österreich, ZIP -> Meist gesehene Nachrichten in Österreich). Diese App wurde in react native (expo) entwickelt. Für die Ständer wurde eine Web App entwickelt. Ich hatte den Lead für die Entwicklung dieser App. Dabei habe ich das gesamte Frontend Programmiert (Web und Mobile), sowie die Technologien entschieden (Backend) z.B. SignalR, damit diese App sauber rennt. Damit diese App für den Coronavirus schnell starten kann, habe ich über Wochen durchgehend gearbeitet, damit das rennt (über Ostern). App kann man im Play Store, sowie im Apple App Store downloaden. Man braucht aber dazu Sensoren.",
            "duaaText": "Dies ist eine Homepage über Duaa Rasho’s geschriebene Bücher. Das Design basiert auf material kit react. In Verwendung der guidelines von Material Design. Diese App wurde mit dem static html Generator gatsby erstellt. Create React app wurde ersetzt durch gatsby um Ressourcen schon beim Build laden zu können, was die Performance besser macht. Es verbessert auch SEO.",
            "cleanbirdText": `Sie können Ihr Reinigungspersonal mit einem Admin-Panel verwalten. Sie können einen Zeitplan für Reinigungskräfte erstellen, wo diese dann wissen, wann und wo zu reinigen ist. 
            Per Push-Benachrichtigung erhalten die Reinigungskräfte eine Benachrichtigung, wenn gereinigt werden soll. 
            In Tabellen kann man einsehen, wann die Reinigunskräfte ihre arbeitet verrichtet haben. Man kann filtern, welche Reinigungsflächen (nach Namen gefiltert) sie gereinigt und welche Aufgaben erledigt wurden. 
            Mit Checkbird ein Teil von der Cleanbird App konnte man die Reinigungsaufgaben des Reinigungspersonals verwalten. Mit dem Cleanbird Device konnten die Reiniger ihre Aufgaben eintragen, die dann in der Tabelle für den Manager zu sehen war.
            Mit Toolbird konne man sehen, wo sich die Cleanbird Devices sich befinden.`,
            "lawyerText": "Weiterentwicklung und Beratung über weitere Projekte. Vortrag und Ratschläge, welche Frontend Technologie zu verwenden u.a. ReactJS VS Angular Vortrag.",
            "trainText": `Komm zum Zug ist eine Webseite über Berufe in Zug Firmen. Leute die an einem Job interessiert sind können sich auf dieser Homepage für einen Job bewerben. Sie können sich die Details eines Jobs anschauen und können leicht herausfinden, ob ein Job zu ihnen passt. Sie können auch sehen, wo der Ort des Jobs ist und von welcher Firma, welches alles in einer Map angezeigt ist. Link: https://komm-zum-zug.at/`,
            "bikeText": `Webapplikation: Radelt.at. In dieser App kann man notieren wie viele Kilometer man mit dem Fahrrad gefahren ist. Es gibt events, wo sich Organisationen Registrieren können und damit auch teilnehmen. Zu beginn setzt du ein Profil auf und damit eine User Registration. Du kannst einstellen, was für ein Radfahrer du bist (Profi, Anfänger, Fortgeschritten). Es gibt eine Rangliste, wo man feststellen kann wer am meisten mit dem Rad fährt. Es wurde in Laravel PHP programmiert, welches den MVC Design Pattern verwendet. Die App wird durch ein Content Management System namens October verwaltet. Es wurde auch eine Adminschnittstelle mittels laravel nova produziert. Hier konnte man die User verwalten, die registriert waren. Somit sammelte ich Erfahrung, wie man komplexe MySQL Datenbanken pflegt. Link: Radelt.at`,
            "doctorText": `In dieser Webseite können sich Ärzte mit einem Code einloggen und damit sehen, wo und wann ein Event startet. Durch Google Maps sieht er, wo der Ort ist. Der Arzt muss auch ein Formular ausfüllen, damit er sich für ein Event registrieren kann. Nachdem das Formular ausgefüllt wurde, wird eine E-Mail an den Organisator über die Details des Arztes gesendet. Der Arzt bekommt eine Bestätigungsmail, dass er sich erfolgreich für ein Event registriert hat. Link: http://patientimfokus.msd.at/`,
            "cheapText": "In dieser Applikation können sie enfach den billigsten Preis einer Komponente in verschieden Geschäften bekommen. Die App sagt dir auch, ob es billiger ist zum Shop selbst zu gehen , du bestellt die Ware online oder du fährst mit dem Auto selber zum Geschäft. Durch den Data Crawler konnte ich die Daten von den verschieden Shops bekommen.",
            "gitDashText": "Zeigt Live Statistiken über die Commits, Pushes, Merges oder Kommentare die ein Entwickler gemacht hatte. Durch Verwendung von Angular Charts wurden die Git Statistiken auf einer Page sehr übersichtlich und professionell dargestellt. Wenn ein Entwickler einen Commit machte, hat die App eine Notifikation von Git erhalten, die dafür sorgte, dass die App die Details des Commits angezeigt hat für ein paar Sekunden und anschließend wieder zurück zu den Statistiken navigierte.",
            "crmText": "Die App musste in AngularJS umgewandelt werden. Eine .NET API Schnittstelle musste Parallel zu den ASP.Net MVC Controller aufgebaut werden.  In der App konnte man die Angestellten verwalten. Man konnte sehen, was sie zu tun haben, in welcher Position sie gerade sind, welcher Kunde von welchen Angestellten betreut wird.",
            "authClientText": "Die App beinhaltete Statistiken und Tabellen z.B. wie viele Transaktionen jeden Tag gemacht wurden, übersicht über alle Firmen Transaktionen, wie hoch die Beträge waren, wie hoch für den gesamten Tag, etc. Man kann selbst Transaktionen durchführt. Die Models des Backends wird auf die View durch AngularJS properties dargestellt. Two-Way-Data-Binding wird dabei angewendet. Wenn ein Input des Users kam, wurden die Properties angepasst und alle Models, die in JSON übertragen wird, konnte man am Backend speichern.",
            "shakeMeChatText": "Entwicklung einer Mobile Messaging App. Erstellung eines massiv skalierbaren Erlang Server.  API Requests vom Client werden extra Threads erstellt, damit der Hauptthread, welcher den UI kontrolliert nicht einfriert. Verwaltung einer Postgre Datenbank, auf die der Erlang Server mittels einer Schnittstelle zugriff. Auch dabei war die lokale Datenbank in Android, welcher ermöglicht caching, sowie Daten permament im System Android zu bewahren.",
            "archetypeText": `
            o	Hosting der Homepage in Wordpress
            o	Performance / Caching redis
            o	Design, Installation des Wordpress Template und Plugins
            o	Anpassungen diverser Elemente, Logo Texte, Fotos
            `,
            "volkswagenText": `
            o	Analysieren des Codes in ASP.NET C# / Javascript (Bug fixing, Refactoring, Configuration)
            o	Azure Dev Ops / Pipelines / Git Repos / Boards, Deployment => Azure App Service
            o	Azure App Service Configuration, Schnelle schon deployte Serivces fixes mit Kudu 
            o	Azure Application Insights für Analysen, Logs, Performance checks
            o	Verwaltung Dependencies (Nugget Packages, Layers)
            o	Neue UnitTest,s um zu checken, ob code Änderungen korrekt sind
            o	Health Checks, um zu sehen, ob web services, APIS, Interfaces, Databases verfügbar sind.
            o	Erstellung von Powershell Scripts (.ps1) die in Azure Dev Ops Pipelines Integriert wurden. Z.B. ür Scheduler und File-Management.
            o	Microsoft Teams als kommunikations Tool
        `,
            "cmnText": `
            DN-Solutions hat den gesamten Entwicklungsprozess von Anfang an des Projekts übernommen. Von laufenden UI/UX-, Performance- und Lesbarkeitsverbesserungen bis hin zur Entwicklung neuer, fortlaufender Funktionen. 
            Für weitere Informationen besuchen Sie uns auf: www.check-me-now.com
        `,
            "variocubeText": `
        Unterstützung der Entwicklung von Smart Schließfachsysteme Software
        Für weitere Informationen besuchen Sie: www.variocube.com
    `,
            "Projects Thus Far": "Bisherige Projekte",
            "Project Duration": "Projekt Dauer",
            "Technology": "Technologie",
            "Company": "Firma",
            "Closed": "Abgeschlossen",
            "Decommissioned, was not completed due to change of plan": "Stillgelegt, wurde aufgrund von Planänderung nicht fertiggestellt",
            "Support ongoing": "Weiterer Support",
            "In Maintenance": "In Wartung",
            "In Development": "In Entwicklung",
            //blog
            "Created": "Erstellt",
            "To the application": "Zur Applikation",
            "to the code": "Zum Code",
            "reactSyntaxText": `Damit kann man schnell und leicht Code Snippets angezeigt. Kann man im Blog öfters sehen, wenn Code angezeigt wird`,
            "reactSpringText": `Animationen in ReactJS. Diese Library macht es einfach Animationen zu starten. Auch komplexe Animationen sind möglich. Es gibt viele Einstellungsmöglichkeiten, z.B. die Duration und die Transformations Animationen werden alle unterstützt.`,
            "skeletonText": `With skeletons you can make the user believe that everything is already loaded, even though everything has yet to finish loading in the background. This allows the homepage to appear faster. Large companies like Youtube use this method in their web application.`,
            //footer
            "Imprint": "Impressum",
            "Usage Service": "Nutzungsbedingunen",
            "Data Protection": "Datenschutzerklärung",
            "language": "Sprache",
        }
    }
}