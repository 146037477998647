import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import "../sass/stylesGlobal.scss"
import { Link, navigate } from 'gatsby';
import { Language, LinkedIn } from '@mui/icons-material';
import Cursor from "../images/cursor-outline.svg"
import { useTranslation } from 'react-i18next';
import CountrySelect from './languagePicker';
import LanguagePicker from './languagePicker';
import XLogoImage from '../images/X-Logo.png'
import CheckMeNowIcon from '../images/check-me-now-icon.png'

import LinkedInLogo from '../images/linkedin.png'
import { useMediaQuery, useTheme } from '@mui/material';


const ResponsiveAppBar = () => {
  const { t, i18n } = useTranslation();

  const pages = [{ key: 'contact', title: t("Support and contact") }, { key: 'services', title: 'Services' }, { key: 'references', title: t("projects and references") }, { key: 'blog', title: 'Blog' }];


  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  const theme = useTheme();
  const onlySmallScreen = useMediaQuery(theme.breakpoints.down("md"));


  return (
    <AppBar position="static">
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Link className="nostyle" to="/">
            <Typography
              variant="h6"
              noWrap
              component="div"
              color="white"
              sx={{ mr: 2, display: { xs: 'none', md: 'flex' } }}
            >
              DN-Solutions   <img src={Cursor} />
            </Typography>
          </Link>
          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' }, alignItems: 'center' }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Link className="nostyle" to="/">
              <Typography
                variant="h6"
                noWrap
                component="div"
                color={'white'}
                sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}
              >
                DN-Solutions   <img src={Cursor} />
              </Typography>
            </Link>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
            >
              <MenuItem onClick={() => {
                handleCloseNavMenu();
                navigate(`/`)
              }}>
                Home
              </MenuItem>
              {pages.map((page) => (
                <MenuItem onClick={() => {
                  handleCloseNavMenu();
                  navigate(`/${page.key}`)
                }}>
                  {page.title}
                </MenuItem>
              ))}
            </Menu>
          </Box>
          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
            {pages.map((page) => {
              return (
                <Link className="nostyle" to={`/${page.key}`}>
                  <Button
                    key={page.key}
                    onClick={handleCloseNavMenu}
                    sx={{ my: 2, color: 'white', display: 'block' }}
                  >
                    {page.title}
                  </Button>
                </Link>
              )
            })}
          </Box>

          <Box style={{ marginRight: 10 }}>
            <Tooltip title="Link: Check-Me-Now">
              <a href='https://www.check-me-now.com'><img style={{ backgroundColor: 'white', borderRadius: 5, borderColor: 'white', border: '1px solid white' }} height={30} src={CheckMeNowIcon} /></a>
            </Tooltip>
          </Box>
          <Box style={{ marginRight: 10 }}>
            <Tooltip title="Link: LinkedIn Profile">
              <a href='https://www.linkedin.com/in/nicholas-grill-abaa17177/'><img style={{ backgroundColor: 'white', borderRadius: 5, borderColor: 'white', border: '1px solid white' }} height={30} src={LinkedInLogo} /></a>
            </Tooltip>
          </Box>
          <Box style={{ marginRight: 10 }}>
            <Tooltip title="Link: X Profile">
              <a href='https://twitter.com/NicholasVonDev'><img src={XLogoImage} height={30} /></a>
            </Tooltip>
          </Box>
          {!onlySmallScreen &&
            <Box sx={{ flexGrow: 0 }}>
              <LanguagePicker />
            </Box>
          }
        </Toolbar>
        {onlySmallScreen &&
          <Toolbar style={{ textAlign: 'center', justifyContent: 'center' }}>
            <Box sx={{ flexGrow: 0 }}>
              <LanguagePicker />
            </Box>
          </Toolbar>
        }
      </Container>
    </AppBar>
  );
};
export default ResponsiveAppBar;
